<template>
  <dryness-template
    :gender="gender"
    :has-some-dryness="hasSomeDryness"
    :city="city"
    :skin-dryness="skinDryness"
    :skin-dryness-ai-selected="skinDrynessAiSelected"
    :facial-dryness-locations="facialDrynessLocations"
    :facial-dryness-locations-ai-selected="facialDrynessLocationsAiSelected"
    :dryness-caused-by-climate="drynessCausedByClimate"
    :dryness-caused-by-climate-ai-selected="drynessCausedByClimateAiSelected"
    :dryness-caused-by-climate-visible="drynessCausedByClimateVisible"
    :dryness-occurrence="drynessOccurrence"
    :dryness-occurrence-ai-selected="drynessOccurrenceAiSelected"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import DrynessTemplate from '@/modules/questionnaire/components/steps/photo-analysis/skin-dryness/dryness/DrynessTemplate';

import { makeStep, resetStoreValues, mapAiSelectedFields } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

import { equalsAny, no, yes } from '@/modules/questionnaire/api/helpers';

import { TEMPERATURE } from '@/modules/questionnaire/api/constants';

const { field, requiredField, requiredArrayField } = fieldBuilder;

const FIELDS_TO_RESET = ['facialDrynessLocations', 'drynessCausedByClimate', 'drynessOccurrence'];

const AI_SELECTED_FIELD_NAMES = ['skinDryness', ...FIELDS_TO_RESET];
const DRYNESS_CONDITIONAL_FIELDS = FIELDS_TO_RESET;

const FIELDS = [
  field('gender'),
  field('temperature'),
  field('countryOfResidence'),
  field('cityOfResidence'),
  requiredField('skinDryness'),
  requiredField('facialDrynessLocations'),
  requiredField('drynessCausedByClimate'),
  requiredArrayField('drynessOccurrence')
];

const COLD_TEMPERATURES = [
  TEMPERATURE.FREEZING,
  TEMPERATURE.VERY_COLD,
  TEMPERATURE.COLD,
  TEMPERATURE.COOL
];

export default {
  name: 'Dryness',
  components: { DrynessTemplate },
  mixins: [makeStep(FIELDS), mapAiSelectedFields(AI_SELECTED_FIELD_NAMES), resetStoreValues],
  computed: {
    hasSomeDryness() {
      return yes(this.skinDryness);
    },
    drynessCausedByClimateVisible() {
      return this.hasSomeDryness && equalsAny(this.temperature, COLD_TEMPERATURES);
    },
    city() {
      return `city.${this.countryOfResidence}.${this.cityOfResidence}`;
    }
  },
  watch: {
    skinDryness(newValue) {
      if (no(newValue)) {
        this.resetStoreFieldsByName(FIELDS_TO_RESET);
        this.showNextStep();
      } else {
        this.scrollTo('#facial-dryness-locations');
      }
    },
    facialDrynessLocations() {
      this.scrollTo('#dryness-caused-by-climate');
    },
    drynessCausedByClimate() {
      this.scrollTo('#dryness-occurrence');
    }
  },
  methods: {
    isFieldVisible(fieldName) {
      if (fieldName === 'drynessCausedByClimate') {
        return this.drynessCausedByClimateVisible;
      }

      if (DRYNESS_CONDITIONAL_FIELDS.includes(fieldName)) {
        return this.hasSomeDryness;
      }

      return true;
    }
  }
};
</script>
